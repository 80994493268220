import axios from 'axios';
import delegate from 'delegate';
import scrollToElement from '../utils/scroll-to-element';

let delegation;
let delegationFilter;
let wrapper;
let messageEl;
let action;
let category;

function changeContent(e) {
    const url = e.delegateTarget.href;
    let paged = url.split('&paged=');
    if (~url.indexOf('&paged=')) {
        paged = url.split('&paged=');
    } else {
        paged = url.split('/page/');
    }
    getPagBlog(paged[1].replace(/[^+\d]/g, ''));
}

function getPagBlog(paged) {
    let targetPage;
    if (paged) {
        targetPage = paged;
    }
    const endpoint = `${contrast.ajax_url}?action=${action}&paged=${targetPage ? targetPage : ''}&category=${
        category ? category : ''
    }`;

    wrapper.classList.add('is-overlay');
    wrapper.classList.add('is-load');
    axios
        .get(endpoint, { action, paged: targetPage ? targetPage : '', category })
        .then((res) => {
            wrapper.classList.remove('is-overlay');
            wrapper.classList.remove('is-load');
            if (res.data && wrapper) {
                wrapper.innerHTML = res.data;
            }
            if (window.locoInstance) {
                window.locoInstance.update();
            }
            scrollToElement(wrapper);

            delegation = delegate('.js-pagination', '.page-numbers', 'click', (e) => {
                e.preventDefault();
                changeContent(e);
                delegation.forEach((del) => del.destroy());
                delegation = null;
            });

            delegationFilter = delegate('.js-content-change-trigger', 'click', (e) => {
                e.preventDefault();
                category = e.delegateTarget.dataset.termid;
                document
                    .querySelectorAll('.js-content-change-trigger')
                    .forEach((but) => but.classList.remove('is-current'));
                e.delegateTarget.classList.add('is-current');
                getPagBlog();
                delegationFilter.destroy();
                delegationFilter = null;
            });
        })
        .catch((res) => console.log(res));
}

function init(container = document) {
    wrapper = container.querySelector('.js-works-content');
    messageEl = container.querySelector('.js-message');
    action = container.querySelector('.js-pagination').dataset.filterAction;
    delegation = delegate('.js-pagination', '.page-numbers', 'click', (e) => {
        e.preventDefault();
        changeContent(e);

        delegation.forEach((del) => del.destroy());
        delegation = null;
    });

    delegationFilter = delegate('.js-content-change-trigger', 'click', (e) => {
        e.preventDefault();
        category = e.delegateTarget.dataset.termid;
        document.querySelectorAll('.js-content-change-trigger').forEach((but) => but.classList.remove('is-current'));
        e.delegateTarget.classList.add('is-current');
        getPagBlog();

        delegationFilter.destroy();
        delegationFilter = null;
    });
    // function (e) {
    // const trigger = e.delegateTarget;
    // const endpoint = trigger.dataset.endpoint;
    // const action = trigger.dataset.action;
    // wrapper.classList.add('is-overlay');
    // wrapper.classList.add('is-load');
    // axios
    //     .post(endpoint, { action: action })
    //     .then((res) => {
    //         wrapper.classList.remove('is-overlay');
    //         wrapper.classList.remove('is-load');
    //         if (res.data.success) {
    //             if (res.data.content && wrapper) {
    //                 wrapper.innerHTML = res.data.content;
    //             }
    //         } else {
    //             if (res.data.message && messageEl) {
    //                 messageEl.innerHTML = res.data.message;
    //             }
    //         }
    //         window.locoInstance.update();
    //         scrollToElement(wrapper);
    //     })
    //     .catch((res) => console.log(res));
    // });
}
function destroy(container = document) {
    delegation.forEach((del) => del.destroy());
    delegation = null;

    delegationFilter.destroy();
    delegationFilter = null;
}

export default { init, destroy };
