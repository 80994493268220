function onScroll(instance) {
    document.documentElement.setAttribute('data-direction', instance.direction);
}

export default {
    init: ()=> {
        if (window.locoInstance) {
            window.locoInstance.on('scroll', onScroll);
        }
    },

    destroy: () => {
        document.documentElement.removeAttribute('data-direction');
    }
}