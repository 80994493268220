import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);
const header = document.querySelector('.js-header');

export default function scrollToElement(element) {
    if (element) {
        if (window.locoInstance) {
            if (window.matchMedia('(max-width: 1024px)').matches) {
                gsap.to(window, {
                    duration: 0.6,
                    scrollTo: {
                        y: element,
                        offsetY: header.offsetHeight,
                    },
                    ease: 'power2.easeOut',
                });
            } else {
                if (window.locoInstance) {
                    window.locoInstance.scrollTo(element, {
                        offset: -header.offsetHeight,
                    });
                }
            }
        } else {
            gsap.to(window, {
                duration: 0.5,
                scrollTo: {
                    y: element,
                    offsetY: header.offsetHeight,
                },
                ease: 'power2.easeOut',
            });
        }
    }
}
