import Lazyload from 'vanilla-lazyload';
import { throttle } from 'throttle-debounce';

function updateLoco() {
    if (window.locoInstance) {
        throttle(50, function() {
            window.locoInstance.update();
        })
    }
}

export const lazyload = new Lazyload({
    elements_selector: '.lazy',
    threshold: 1000,
    use_native: true,
    callback_loaded: updateLoco
});