function findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}

function onFocus() {
    let label = findAncestor(this, 'text-input');
    this.classList.add('is-fill');
    label?.classList.add('is-fill');
}

function onBlur() {
    if (this.value == '') {
        let label = findAncestor(this, 'text-input');
        this.classList.remove('is-fill');
        label?.classList.remove('is-fill');
    }
}

function onReady(input) {
    if (input.value !== '') {
        let label = findAncestor(input, 'text-input');
        input?.classList.add('is-fill');
        label?.classList.add('is-fill');
    } else {
        let label = findAncestor(input, 'text-input');
        input?.classList.remove('is-fill');
        label?.classList.remove('is-fill');
    }
}

function init(container) {
    const inputs = Array.from(container.querySelectorAll('.js-input-focus'));
    inputs.forEach((input) => {
        input.addEventListener('focus', onFocus);
        input.addEventListener('blur', onBlur);
        onReady(input);
    });
}

function destroy(container) {
    const inputs = Array.from(container.querySelectorAll('.js-input-focus'));

    inputs.forEach((input) => {
        input.removeEventListener('focus', onFocus);
        input.removeEventListener('blur', onBlur);
    });
}

export default { init, destroy };
