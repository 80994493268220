import LocomotiveScroll from 'locomotive-scroll';

import { triggerCustomEvent } from '../utils/trigger-events';

function init(container = document) {
    if (container.querySelector('[data-scroll-container]')) {
        if (window.matchMedia('(min-width: 1280px)').matches) {
            window.locoInstance = new LocomotiveScroll({
                el: container.querySelector('[data-scroll-container]'),
                smooth: true,
                getDirection: true,
                lerp: 0.1,
            });
            triggerCustomEvent(window, 'scroll-initialized');
        }
    }
}

function destroy() {
    if (window.locoInstance) {
        window.locoInstance.destroy();
        window.locoInstance = null;
    }
}

function update() {
    if (window.locoInstance) {
        window.locoInstance.update();
    }
}

export default { init, destroy, update };
