import type { IViewData } from '@barba/core';
import tab from '../inits/tab';
import clickCollapse from '../inits/click-collapse';
import { Collapse } from '../custom-elements/Collapse/Collapse';
import workPopup from '../inits/work-popup';

let faqCollapses: Collapse[] = [];
function onExpand(this: Collapse) {
    faqCollapses.filter((el) => el !== this).forEach((el) => el.collapse());
}

export default {
    namespace: 'index-page',
    beforeEnter({ next }: IViewData) {
        faqCollapses = Array.from(next.container.querySelectorAll<Collapse>('.js-faq-collapse'));
        clickCollapse.init(next.container);
        faqCollapses.forEach((el) => {
            el.addEventListener('expand', onExpand);
        });
    },
    once({next}: IViewData) {
        workPopup.init(next.container);
    },
    afterEnter({next, current}: IViewData) {
        tab.init(next.container);
        workPopup.init(next.container);
    },
    beforeLeave({current}: IViewData) {
        faqCollapses.forEach((el) => {
            el.removeEventListener('expand', onExpand);
        });
    },
    afterLeave({current}: IViewData) {
        clickCollapse.destroy(current.container);
        faqCollapses = [];
    },
}
