function onClick(e) {
    this.classList.toggle('is-expanded');
    const collapse = document.querySelector(`[data-collapse-id=${this.dataset.collapseTrigger}]`);
    collapse?.toggle(e);
}

function init(container = document) {
    const collapseTrigger = container.querySelectorAll('[data-collapse-trigger]');
    if (collapseTrigger.length > 0) {
        collapseTrigger.forEach(item => {
            item.addEventListener('click', onClick);
        });
    }
};

function destroy(container = document) {
    const collapseItems = container.querySelectorAll('[data-collapse-trigger]');
    collapseItems.forEach(item => {
        item.removeEventListener('click', onClick);
    });
}

const _module = { init, destroy };

export default _module;