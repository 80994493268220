import LitPopup from 'lit-popup';
import barba from '@barba/core';

import { dispatcher } from './dispatcher';
import { lockBodyScroll, unlockBodyScroll } from '../utils/lock-body-scroll';

const map = new Map();

function init(container = document) {
    const elements = Array.from(container.querySelectorAll('[data-lit-popup]'));
    elements.forEach((el) => {
        const name = el.dataset.litPopup;
        let wasBodyLocked = false;

        if (!name) {
            throw new Error('[lit-popup] Name should be provided.');
        }

        const lockScroll = () => {
            wasBodyLocked = document.body.classList.contains('no-scroll');

            if (!wasBodyLocked) {
                lockBodyScroll();
            }
        };

        const unlockScroll = () => {
            if (!wasBodyLocked) {
                unlockBodyScroll();
            }
        };

        const popup = new LitPopup(name, {
            onOpen: () => {
                lockScroll();
            },
            onOpenComplete: () => {
                dispatcher.dispatch('popup-opened', { name });
            },
            onClose: () => {
                dispatcher.dispatch('popup-closed', { name });
            },
            onCloseComplete: () => {
                let form = el.querySelector('form[data-clear-on-popup-close]');
                if (form) {
                    clearForm(form);
                }
                unlockScroll();
            },
        });

        barba.hooks.leave(() => {
            if (popup.isOpen) {
                popup.close();
            }
        });

        map.set(el, popup);
    });
}

function destroy(container = document) {
    const elements = Array.from(container.querySelectorAll('[data-lit-popup]'));

    elements.forEach((el) => {
        const popup = map.get(el);

        if (popup) {
            popup.destroy();
            map.delete(el);
        }
    });
}

function getInstanceByElement(element) {
    return element ? map.get(element) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
