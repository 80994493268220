import barba from '@barba/core';
import popups from './inits/lit-popup';

import hideHeaderOnScroll from './inits/hide-header-on-scroll';
import scroll from './inits/scroll';
import burgerPopup from './inits/burger-popup';
import inputFocus from './inits/input-focus';
import ajaxForms from './inits/ajax-forms';
import mask from './inits/mask';
import anchor from './inits/anchor';
import { lazyload } from './inits/lazyload';


import { ScrollTrigger } from 'gsap/ScrollTrigger';

barba.hooks.once(({ current, next }) => {
    popups.init();
    popups.init(next.container);
});

barba.hooks.beforeEnter(({ current, next }) => {
    window.scrollTo(0, 0);
    burgerPopup.init();
    lazyload.update();
    inputFocus.init(next.container);
    ajaxForms.init(next.container);
    mask.init(next.container);
    anchor.init(next.container);
    if (current?.namespace && next?.namespace) {
        document.body.classList.remove(current.namespace);
        document.body.classList.add(next.namespace);
    }
});

function goToAnchor() {
    if (window.locoInstance) {
        window.locoInstance.update();
        window.locoInstance.scrollTo(document.querySelector(window.location.hash));
    }
}

barba.hooks.afterEnter(({ next, trigger }) => {
    setTimeout(() => {
        scroll.init(next.container);
        hideHeaderOnScroll.init();
        if (trigger == 'back' || trigger == 'forward') {
            setTimeout(() => {
                if (window.locoInstance && next.namespace) {
                    window.locoInstance.scrollTo(0, localStorage.getItem(next.namespace) | 0);
                }
            }, 100);
        }
        if (trigger !== 'back' && trigger !== 'forward' && window.location.hash) {
            goToAnchor();
            window.addEventListener('scroll-initialized', goToAnchor);
        }
        if (window.locoInstance) {
            window.locoInstance.update();
        }
    }, 100);
});

barba.hooks.after(({next}) => {
    popups.init(next.container);
    setTimeout(()=> {
        scroll.update();
    },100)
});

barba.hooks.beforeLeave( ({current}) => {
    popups.destroy(current.container);
    if (ScrollTrigger.getAll().length > 0) {
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill()
        });
    }
    if (window.locoInstance && current.namespace) {
        localStorage.setItem(current.namespace, window.locoInstance.scroll.instance.scroll.y);
    }
});

barba.hooks.afterLeave(({ current }) => {
    scroll.destroy();
    burgerPopup.destroy();
    hideHeaderOnScroll.destroy();
    inputFocus.destroy(current.container);
    mask.init(current.container);
    ajaxForms.destroy(current.container);
    anchor.destroy(current.container);
});