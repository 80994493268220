import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import burgerPopup from './burger-popup';


function onClick(e) {
    e.preventDefault();
    const burgerPopupEl = document.querySelector('[data-lit-popup="burger"]');
    const popup = burgerPopup.getInstanceByElement(burgerPopupEl);
    if (e.target && e.target.hash) {
        let target = document.querySelector(e.target.hash);

        let header = document.querySelector('.js-header');
        if (burgerPopupEl && burgerPopupEl.classList.contains('lit-popup--opened')) {
            popup.close();
        }
        if (window.locoInstance) {
            window.locoInstance.scrollTo(target, (header.offsetHeight));
            window.locoInstance.update();
        } else {
            gsap.to(window, {
                duration: 0.5,
                scrollTo: {
                    y: target,
                    offsetY: header.offsetHeight,
                },
                ease: 'power2.easeOut',
            });
        }
    }
}

function init(container = document) {
    let links = Array.from(container.querySelectorAll('.js-link-anchor'));

    links.forEach((link) => link.addEventListener('click', onClick));
}

function destroy(container = document) {
    let links = Array.from(container.querySelectorAll('.js-link-anchor'));

    links.forEach((link) => link.removeEventListener('click', onClick));
}

export default { init, destroy };
