import IMask from 'imask';

const map = new Map();

function init(container = document) {
    const tels = Array.from(container.querySelectorAll('input[data-mask="tel"]'));

    tels?.map((tel) => {
        const imask = IMask(tel, { mask: '+{7} (000) 000-00-00' });
        map.set(tel, imask);
    });
}

function destroy(container = document) {
    const tels = Array.from(container.querySelectorAll('input[data-mask="tel"]'));

    tels?.map((tel) => {
        const imask = getInstanceByElement(tel);

        if (imask) {
            imask.destroy();
            map.delete(tel);
        }
    });
}

function getInstanceByElement(element) {
    return element ? map.get(element) : undefined;
}

export default { init, destroy };
