import gsap from 'gsap';
import pageTransition from '../animations/page-transition';
import timeout from '../utils/timeout';

function contentAnimation(next) {

    const duration = 0.4;
    const ease = 'power1.inOut';

    const tl = gsap.timeline({
        onComplete: () => {
            if (typeof onComplete === 'function') {
                onComplete();
            }
            tl.kill();
        }
    });
    // Insert your page animations after this
}

export default {
    name: 'to-main',
    to: {
        namespace: 'index-page',
    },

    async leave() {
        const done = this.async();

        pageTransition();
        await timeout(600);
        done();
    },

    async enter({next}) {
        contentAnimation(next);
    },

    async once({next}) {
        contentAnimation(next);
    },

}