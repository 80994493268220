import type { IViewData } from '@barba/core';
import tab from '../inits/tab';

export default {
    namespace: 'services-page',
    beforeEnter({ next }: IViewData) {
    },
    afterEnter({next, current}: IViewData) {
        tab.init(next.container);
    },
    beforeLeave({current}: IViewData) {
    },
    afterLeave({current}: IViewData) {

    },
}
