import { fetchAjaxFormSender, fetchValidator } from '../dynamic-modules';
import popups from './lit-popup';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<HTMLFormElement, Record<string, any>>();
let messageEl;

const showMessage = (form: HTMLFormElement, message = '') => {
    const messageModal = popups.getInstanceByElement(messageEl);
    const messageTextEl = messageEl.querySelector('.js-message-text');

    messageTextEl.innterHTML = message;
    messageModal?.open();
};


async function init(container: Element | Document = document) {
    messageEl = container.querySelector('[data-lit-popup="message"]')
    const forms = Array.from(container.querySelectorAll(SELECTOR)) as HTMLFormElement[];
    if (forms.length > 0) {
        const [{ default: createFormSender }, { default: createValidator, clearMessages }] = await Promise.all([
            fetchAjaxFormSender(),
            fetchValidator(),
        ]);

        forms.forEach((form) => {
            const validator = createValidator(form);
            const sender = createFormSender(form, {
                shouldClearInputs: true,
                onBeforeSend: () => {
                    form.classList.add('is-load');
                },
                onSuccess: (res) => {
                    form.classList.remove('is-load');
                    showMessage(res.message);
                },
                onError: (res) => {
                    form.classList.remove('is-load');
                },
                onComplete: () => {
                },
            });
            map.set(form, sender);

            form.addEventListener('submit', (event) => {
                event.preventDefault();
                const isFormValid = validator.validate();
                if (isFormValid) {
                    sender.send();
                }
            });
        });
    }
}

function destroy(container: Element | Document = document) {
    //
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
