import './webpack-imports';
import './sentry';
import './polyfills';
import './custom-elements';

import preloader from './components/preloader.ts';
import anchor from './inits/anchor';

// Libraries
import barba from '@barba/core';
import prefetch from '@barba/prefetch';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Views
import IndexPageView from './views/index-page';
import BlogsPageView from './views/blogs-page';
import WorksPageView from './views/works-page';
import AboutPageView from './views/about-page';
import ServicesPageView from './views/services-page';

// Transitions
import defaultTransition from './transitions/default-transition.js';
import ToIndexTransition from './transitions/to-index.js';

import './barba-global';

window.scrollTo(0,0);
document.addEventListener('DOMContentLoaded', () => {
    preloader.init();
    gsap.registerPlugin(ScrollTrigger);
    gsap.config({nullTargetWarn: false});
    barba.use(prefetch);
    document.addEventListener('preloader.complete', barbaInit);
    anchor.init();
    function barbaInit() {
        barba.init({
            transitions: [
                ToIndexTransition,
                defaultTransition
            ],
            views: [
                IndexPageView,
                BlogsPageView,
                WorksPageView,
                AboutPageView,
                ServicesPageView
            ],
            debug: true,
            prefetchIgnore: true,
            preventRunning: true,
            prevent: ({ el }) => el.classList && (el.classList.contains('page-numbers') || el.classList.contains('js-content-change-trigger')),
            timeout: 5000
        });
    }
});

module.hot?.accept();
