import Glide from '@glidejs/glide'


function init(container: HTMLElement | Document = document) {
	const workPopup = document.querySelector('[data-lit-popup="work"]');
	workPopup?.addEventListener('open', (instance) => {
		let opener = instance.detail.triggerElement;
		if (!instance.detail.triggerElement.classList.contains('js-work-popup-opener')) {
			opener = instance.detail.triggerElement.closest('.js-work-popup-opener');
		}
		const workDescr = workPopup.querySelector('.js-work-descr');
		const workSlider = workPopup.querySelector('.js-work-slider');
		const data = JSON.parse(opener.dataset.itemData);
		const { title } = data;
		const { description } = data;
		const { paints } = data;
		const { sizes } = data;
		const { custom } = data;
		const { photos } = data;
		let titleEl = '';
		let descritptionEl = '';
		let paintsEl = '';
		let sizesEl = '';
		let sliderLayout = '';
		let customEl = '';
		if (title) {
			titleEl = `
				<h3 class="h4 work-popup-title">${title}</h3>
			`;
		}
		if (description) {
			descritptionEl = `
				<p class="work-popup-description">${description}</p>
			`;
		}
		if (sizes) {
			let width = '';
			let height = '';
			if (sizes.width) {
				width = `Ширина - ${sizes.width}`;
			}
			if (sizes.height) {
				height = `Высота - ${sizes.height}`;
			}
			if (width !== '' && height !== '') {
				sizesEl = `
					<h4 class="h5">
						Размеры
					</h4>
					<p>Ширина - ${sizes.width}</p>
					<p>Высота - ${sizes.height}</p>
				`;
			}
		}
		if (paints && paints.length > 0) {
			let paintsList = '';
			paints.forEach(paint => {
				paintsList += `<li>${paint}</li>`;
			})
			paintsEl = `
				<h4 class="h5">
					Использованные краски
				</h4>
				<ul>
					${paintsList}
				</ul>
			`;
		}
		if (custom && custom.length > 0) {
			custom.forEach(field => {
				let list = '';
				if (field.list && field.list.length > 0) {
					field.list.forEach(item => {
						list += `<li>${item}</li>`;
					});
				}
				customEl += `
				<h4 class="h5">
					${field.title}
				</h4>
				<ul>
					${list}
				</ul>
				`;
			});
		}
		if (photos && photos.length > 0) {
			let slidesList = '';
			photos.forEach(photo => {
				slidesList += `
					<li class="glide__slide">
						<div class="work-popup-photo">
							<img src="${photo}">
						</div>
					</li>
				`;
			});
			sliderLayout = `
				<div class="glide">
					<div class="glide__track" data-glide-el="track">
						<ul class="glide__slides">
							${slidesList}
						</ul>
					</div>
					<div class="glide__arrows" data-glide-el="controls">
						<button class="glide__arrow glide__arrow--left" data-glide-dir="<">
							<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect x="46" y="46" width="46" height="46" rx="23" transform="rotate(-180 46 46)" fill="white"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M23.1381 27.7908C22.8777 28.0697 22.4556 28.0697 22.1953 27.7908L18.1953 23.5051C17.9349 23.2261 17.9349 22.7739 18.1953 22.4949L22.1953 18.2092C22.4556 17.9303 22.8777 17.9303 23.1381 18.2092C23.3984 18.4882 23.3984 18.9404 23.1381 19.2194L20.2761 22.2857H29.3333C29.7015 22.2857 30 22.6055 30 23C30 23.3945 29.7015 23.7143 29.3333 23.7143H20.2761L23.1381 26.7806C23.3984 27.0596 23.3984 27.5118 23.1381 27.7908Z" fill="#3F3F3F"/>
							</svg>
						</button>
						<button class="glide__arrow glide__arrow--right" data-glide-dir=">">
							<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="46" height="46" rx="23" fill="#EFEFEF"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M22.8619 18.2092C23.1223 17.9303 23.5444 17.9303 23.8047 18.2092L27.8047 22.4949C28.0651 22.7739 28.0651 23.2261 27.8047 23.5051L23.8047 27.7908C23.5444 28.0697 23.1223 28.0697 22.8619 27.7908C22.6016 27.5118 22.6016 27.0596 22.8619 26.7806L25.7239 23.7143H16.6667C16.2985 23.7143 16 23.3945 16 23C16 22.6055 16.2985 22.2857 16.6667 22.2857H25.7239L22.8619 19.2194C22.6016 18.9404 22.6016 18.4882 22.8619 18.2092Z" fill="#3F3F3F"/>
							</svg>
						</button>
					</div>
				</div>
			`;
		}
		if (workDescr) {
			workDescr.innerHTML = `
				${titleEl}
				${descritptionEl}
				${paintsEl}
				${sizesEl}
				${customEl}
			`;
		}
		if (photos && photos.length > 0 && workSlider) {
			workSlider.innerHTML = sliderLayout;
			if (photos.length > 1) {
				new Glide('.glide', {
					gap: 0,
				}).mount();
			}
		}
	})
}

const _module = { init };

export default _module;