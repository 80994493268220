import gsap from 'gsap';

export default function pageTransition() {
    const tl = gsap.timeline();
    tl.to('.loading-screen', {
        duration: 0.6,
        width: '100%',
        left: '0%',
        ease: 'Expo.easeInOut'
    });
    tl.to('.loading-screen', {
        duration: 0.6,
        width: '100%',
        left: '100%',
        ease: 'Expo.easeInOut',
        delay: 0.15
    });
    tl.set('.loading-screen', {
        left: '-100%'
    });
}