import type { IViewData } from '@barba/core';
import changeWorks from '../inits/changeWorks';

export default {
    namespace: 'blogs-page',
    beforeEnter({ next }: IViewData) {
    },
    afterEnter({next, current}: IViewData) {
        changeWorks.init(next.container);
    },
    beforeLeave({current}: IViewData) {
        changeWorks.destroy(current.container);
    },
    afterLeave({current}: IViewData) {

    },
}
