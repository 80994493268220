let isMobile, handler;
const duration = 600;
let timer;

function onClick() {
    let href = this.classList.contains('js-tab-link') ? this : this.closest('.js-tab-link');
    if (href.dataset.link && href.classList.contains('is-active')) {
        barga.go(href.dataset.link);
    }
}

function init(container = document) {
    const tabs = Array.from(container.querySelectorAll('[data-tab]'));

    tabs.forEach((tab) => {
        const links = Array.from(tab.querySelectorAll('[data-tab-link]'));
        const contents = Array.from(tab.querySelectorAll('[data-tab-content]'));

        let currentTabs = contents.filter((content, i) => i % links.length == 0);
        let currentLink = links[0];
        currentTabs.forEach((tab) => tab.classList.add('is-active'));
        currentLink.classList.add('is-active');
        let oldTabs;
        let oldLink;
        links.forEach((link) => {
            link.addEventListener('click', function () {
                if (this.classList.contains('.js-tab-link') && this.classList.contains('.is-active')) {
                    return;
                } else if (this.closest('.js-tab-link') && this.classList.contains('.is-active')) {
                    return;
                } else if (this === currentLink) {
                    return;
                }
                oldLink = currentLink;
                if (this.classList.contains('.js-tab-link')) {
                    currentLink = this;
                } else {
                    currentLink = this.closest('.js-tab-link');
                }

                oldTabs = currentTabs;
                oldLink?.classList.remove('is-active');
                clearTimeout(timer);
                oldTabs?.forEach((oldTab) => oldTab.classList.remove('is-hide'));
                oldTabs?.forEach((oldTab) => oldTab.classList.remove('is-active'));
                oldTabs?.forEach((oldTab) => oldTab.classList.add('is-hide'));
                timer = setTimeout(() => {
                    oldTabs?.forEach((oldTab) => oldTab.classList.remove('is-hide'));
                    oldTabs?.forEach((oldTab) => oldTab.classList.remove('is-active'));
                }, duration);

                currentTabs = tab.querySelectorAll(`[data-tab-content=${currentLink.dataset.tabLink}]`);
                currentLink.classList.add('is-active');
                currentTabs.forEach((currentTab) => currentTab.classList.add('is-active'));
            });

            link.addEventListener('click', onClick);
        });
    });
}

function destroy(container = document) {}

export default { init, destroy };
